<template>
    <v-alert
        border="top"
        icon="mdi-information"
        :color="color"
        prominent
        dark
        dismissible
    >
        <v-avatar size="28" :color="`${color} darken-2`" class="white--text">{{
            requestCount
        }}</v-avatar>
        reference requests sent with current status of
        <v-chip class="ml-2 text-uppercase" color="primary darken-2" small>
            <v-icon small class="mr-2">mdi-send</v-icon>
            {{ requestStatus }}
        </v-chip>
    </v-alert>
</template>
<script>
export default {
    name: "rental-employment-status-bar",
    components: {},
    props: {
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        requestCount: {
            type: Number,
            required: false,
            default: 0,
        },
        requestStatus: {
            type: String,
            required: false,
            default: "Sending",
        },
    },
    data() {
        return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    updated() {},
    detroyed() {},
    methods: {},
};
</script>

<style scoped>
</style>