var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-alert",
    {
      attrs: {
        border: "top",
        icon: "mdi-information",
        color: _vm.color,
        prominent: "",
        dark: "",
        dismissible: ""
      }
    },
    [
      _c(
        "v-avatar",
        {
          staticClass: "white--text",
          attrs: { size: "28", color: _vm.color + " darken-2" }
        },
        [_vm._v(_vm._s(_vm.requestCount))]
      ),
      _vm._v(" reference requests sent with current status of "),
      _c(
        "v-chip",
        {
          staticClass: "ml-2 text-uppercase",
          attrs: { color: "primary darken-2", small: "" }
        },
        [
          _c("v-icon", { staticClass: "mr-2", attrs: { small: "" } }, [
            _vm._v("mdi-send")
          ]),
          _vm._v(" " + _vm._s(_vm.requestStatus) + " ")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }